/* eslint-disable no-const-assign */
import React, { useState } from "react";
import NavBar from "../Components/NavBar";
import CartSection from "./CartSection";
import IconButton from "../Components/IconStyle";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToCart } from "../store/cartSlice";
import { ImageUrl } from "../firebaseConfig";
import { translate } from "../util/TranslateFunction";
import OptionsModal from "../Components/OptionsModal";
import { generateUidWithTime } from "../util/GenerateUid";

function AddToCart() {
  const navigate = useNavigate();
  const location = useLocation();
  // const [quantity, setQuantity] = useState(1);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const returnToProductPg = () =>
    navigate(`${location.pathname.replace("/addToCart", "")}`);
  const item = location.state;

  const showModal = () => {
    setOpen(true);
    document.body.style.overflow = "hidden";
  };
  const handleAddToCart = (item, id) => {
    if (item.groupIds) {
      showModal();
      return;
    }
    const cartItem = {
      id,
      ...item,
      cartItemKey: generateUidWithTime(),
      quantity: 1,
      options: [],
    };
    dispatch(addToCart(cartItem));
  };
  return (
    <>
      <NavBar />
      <div className="px-[25px]">
        <div className="flex flex-row gap-[50px]">
          <div className="w-full md:w-3/5">
            <div className="mt-2">
              <div className="flex justify-between items-center  pt-[15px] px-[11px]  w-full">
                <button
                  type="button"
                  onClick={returnToProductPg}
                  className="focus:outline-none px-2 text-white bg-[#eb6700] hover:bg-[#eb6700] h-[28px]  font-medium rounded-lg text-sm p-1  "
                >
                  <IconButton
                    width="15px"
                    height="15px"
                    backgroundPosition="-728px 725px"
                  />
                </button>
              </div>

              {item.details.downloadToken && (
                <img
                  src={`${ImageUrl}${item.details.downloadToken}`}
                  alt="_food"
                  className="mt-1 w-full rounded-[18px] flex items-center justify-center h-[366.21px]"
                />
              )}
            </div>
            <h2 className="text-[#181E22] font-syne text-2xl md:text-[30px] font-medium mt-[9px]">
              {item?.details?.name}
            </h2>
            <div className="flex justify-between gap-3 pt-[16px]">
              <p className="text-[#FE724C] font-epilogue text-base md:text-[20px] font-medium">
                Kr {item?.details?.price}
              </p>
              {/* <div className="flex gap-2 justify-center items-center">
            <span
              className="text-[#eb6700] border border-[#eb6700] pt-[1px] pb-[5px] px-3 rounded-full"
              onClick={() => quantity > 1 && setQuantity((prev)=> prev-1)}
            >
              -
            </span>
            <span className="text-[#5B5B5E] font-epilogue text-base font-normal leading-6">
              {quantity}
            </span>{" "}
            <span
            className="text-white bg-[#eb6700] pt-[3.5px] pb-[5px] px-[11px] rounded-full"
              onClick={() => setQuantity((prev)=> prev+1)}
            >
              +
            </span>
          </div> */}
            </div>
            <p className="pt-[30px] text-[#5B5B5E] font-epilogue text-base md:text-[18px] font-normal leading-6">
              {item?.details?.description}
            </p>

            <div className="flex justify-center items-center">
              <button
                disabled={item.details.is_out_of_stock}

                onClick={() => {
                  handleAddToCart(item.details, item.id);
                }}
                className="flex justify-center items-center rounded-full my-[40px] bg-[#eb6700] gap-2 pl-2 pr-5 py-2 text-white font-syne font-medium normal-case"
              >
                <span className="rounded-full p-3 flex justify-center align-center bg-white">
                  <IconButton
                    width="20px"
                    height="20px"
                    backgroundPosition="-387px 524px"
                  />
                </span>

                {translate("add_to_cart")}
              </button>
            </div>
          </div>
          <div className=" hidden md:block w-2/5 bg-[#f9f9f9] rounded-[4px]">
            <div className="flex flex-col gap-[12px] justify-center items-center mt-[38px]">
              <p className="text-[#14171A] font-syne text-[24px] font-bold leading-normal">
                Your Cart
              </p>
              <p className="text-[#14171A] text-center font-epilogue text-[16px] font-normal leading-[1.415px]">
                Your Order from bistroboost
              </p>
            </div>
            <div className="p-[30px]">
              <CartSection />
            </div>
          </div>
        </div>
      </div>

      <OptionsModal open={open} setOpen={setOpen} item={item.details} />
    </>
  );
}

export default AddToCart;
