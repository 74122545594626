import React from 'react'
import NavBar from '../Components/NavBar'

function PurchasePersonalDataController() {
  return (
    <>
    <NavBar/>
    <div>
      <p
        style={{
          marginTop: "15pt",
          marginBottom: "15pt",
          lineHeight: "normal",
          border: "0.75pt solid #d9d9e3",
          fontSize: "12pt"
        }}
      >
        <strong>
          <span
            style={{
              border: "0.75pt solid #d9d9e3",
              fontFamily: '"Segoe UI"',
              color: "#374151"
            }}
          >
            Köpvillkor för Personuppgiftsansvarig - bistroboost -
            Matbeställningsapp
          </span>
        </strong>
      </p>
      <div
        style={{
          marginLeft: "12.25pt",
          border: "0.75pt solid #d9d9e3",
          clear: "both"
        }}
      >
        <ol type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
          <li
            style={{
              marginLeft: "32.07pt",
              lineHeight: "normal",
              paddingLeft: "3.93pt",
              fontFamily: '"Segoe UI"',
              fontSize: "12pt",
              color: "#374151"
            }}
          >
            <strong>
              <span style={{ border: "0.75pt solid #d9d9e3" }}>
                Personuppgiftsansvarig:
              </span>
            </strong>{" "}
            bistroboost, med organisationsnummer 559208-8065, är
            personuppgiftsansvarig för behandlingen av personuppgifter enligt
            dessa köpvillkor.
          </li>
          <li
            style={{
              marginLeft: "32.07pt",
              lineHeight: "normal",
              paddingLeft: "3.93pt",
              fontFamily: '"Segoe UI"',
              fontSize: "12pt",
              color: "#374151"
            }}
          >
            <strong>
              <span style={{ border: "0.75pt solid #d9d9e3" }}>
                Användning av Matbeställningsappen:
              </span>
            </strong>{" "}
            Genom att använda matbeställningsappen accepterar du dessa köpvillkor
            och samtycker till att dina personuppgifter behandlas enligt vår
            integritetspolicy.
          </li>
          <li
            style={{
              marginLeft: "32.07pt",
              lineHeight: "normal",
              paddingLeft: "3.93pt",
              fontFamily: '"Segoe UI"',
              fontSize: "12pt",
              color: "#374151"
            }}
          >
            <strong>
              <span style={{ border: "0.75pt solid #d9d9e3" }}>
                Beställningshantering:
              </span>
            </strong>{" "}
            a. Vid beställning via appen behandlar vi följande personuppgifter:
            Personlig identifieringskod, E-postadress, Platsdata* (vid samtycke),
            För- och efternamn, Lösenord, Mobilnummer, Inställningar
            (push-notiser)*, Registreringsnummer och beskrivning av bil (vid
            användning av "bilbeställning"). b. Rättslig grund: Avtal, samtycke
            för platsdata. c. Lagringsperiod: Personuppgifter sparas så länge
            användarkontot är aktivt. d. Delas med: Virtual Ways AB (plattform),
            Nets (betalningar).
          </li>
          <li
            style={{
              marginLeft: "32.07pt",
              lineHeight: "normal",
              paddingLeft: "3.93pt",
              fontFamily: '"Segoe UI"',
              fontSize: "12pt",
              color: "#374151"
            }}
          >
            <strong>
              <span style={{ border: "0.75pt solid #d9d9e3" }}>
                Säker Digital Miljö och Dataskydd:
              </span>
            </strong>{" "}
            a. Vi använder tekniska och organisatoriska åtgärder för att
            säkerställa en trygg digital miljö för användare. b. Kategorier av
            personuppgifter: Identitetsuppgifter, Platsdata*, Teknisk data*,
            Transaktionsdata*. c. Rättslig grund: Berättigat intresse. d.
            Lagringsperiod: 30 dagar. e. Delas med: Virtual Ways AB.
          </li>
          <li
            style={{
              marginLeft: "32.07pt",
              lineHeight: "normal",
              paddingLeft: "3.93pt",
              fontFamily: '"Segoe UI"',
              fontSize: "12pt",
              color: "#374151"
            }}
          >
            <strong>
              <span style={{ border: "0.75pt solid #d9d9e3" }}>
                Kamerabevakning vid Beställningsplatsen:
              </span>
            </strong>{" "}
            a. Kamerabevakning används för att förebygga, förhindra och upptäcka
            brott eller störningar. b. Kategorier av personuppgifter: Bild/film
            (ej ljud). c. Rättslig grund: Berättigat intresse. d. Lagringsperiod:
            72 timmar. e. Delas med: Endast med Polismyndigheten och eventuellt
            försäkringsbolag vid behov.
          </li>
          <li
            style={{
              marginLeft: "32.07pt",
              lineHeight: "normal",
              paddingLeft: "3.93pt",
              fontFamily: '"Segoe UI"',
              fontSize: "12pt",
              color: "#374151"
            }}
          >
            <strong>
              <span style={{ border: "0.75pt solid #d9d9e3" }}>
                Informera om Erbjudanden och Nyheter:
              </span>
            </strong>{" "}
            a. Vid samtycke informerar vi användare om erbjudanden och nyheter via
            appen. b. Kategorier av personuppgifter: Transaktionsdata*,
            Inställningar (push-notiser)*. c. Rättslig grund: Samtycke. d.
            Lagringsperiod: Så länge samtycke finns. e. Delas med: Virtual Ways
            AB.
          </li>
          <li
            style={{
              marginLeft: "32.07pt",
              lineHeight: "normal",
              paddingLeft: "3.93pt",
              fontFamily: '"Segoe UI"',
              fontSize: "12pt",
              color: "#374151"
            }}
          >
            <strong>
              <span style={{ border: "0.75pt solid #d9d9e3" }}>Cookies:</span>
            </strong>{" "}
            Vi använder cookies. Läs mer i vår cookie policy.
          </li>
          <li
            style={{
              marginLeft: "32.07pt",
              lineHeight: "normal",
              paddingLeft: "3.93pt",
              fontFamily: '"Segoe UI"',
              fontSize: "12pt",
              color: "#374151"
            }}
          >
            <strong>
              <span style={{ border: "0.75pt solid #d9d9e3" }}>
                Ändringar i Villkoren:
              </span>
            </strong>{" "}
            Vi förbehåller oss rätten att göra ändringar i dessa köpvillkor. Den
            senaste versionen finns i appen och på plats där digitala
            beställningar görs. Ändringar som påverkar våra åtaganden mot dig
            kommer att meddelas vid nästa beställning eller per e-post om du har
            registrerat ett användarkonto hos oss.
          </li>
        </ol>
      </div>
      <p
        style={{
          marginTop: "15pt",
          marginBottom: "15pt",
          lineHeight: "normal",
          border: "0.75pt solid #d9d9e3",
          fontSize: "12pt"
        }}
      >
        <span style={{ border: "0.75pt solid #d9d9e3" }}>
          <em>
            <span style={{ fontFamily: '"Segoe UI"', color: "#374151" }}>
              Sist uppdaterad: 2024-03-19
            </span>
          </em>
        </span>
      </p>
      <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>&nbsp;</p>
    </div>
 
  </>
  
  )
}

export default PurchasePersonalDataController