import NavBar from "../Components/NavBar";
import IconButton from "../Components/IconStyle";
import {useLocation, useNavigate} from "react-router-dom";
import { useEffect, useState } from "react";
import { get, ref } from "firebase/database";
import { rdb } from "../firebaseConfig";
import { useSelector } from "react-redux";

function Order() {
  const navigate = useNavigate();
  const location = useLocation();
  const [estimatedTime, setEstimatedTime] = useState(30)
  const order_type = useSelector((state) => state.cart.order_type);
  useEffect(()=>{
    const dataRef = ref(rdb, 'estimatedTime');

// Reading data using the GET method
get(dataRef).then((snapshot) => {
  if (snapshot.exists()) {
    const data = snapshot.val();
    if(order_type==="delivery"){
    setEstimatedTime(Number(data)+15);}
    else{
      setEstimatedTime(data);
    }
    console.log('Data from Firebase Realtime Database:', data);
  } else {
    console.log('No data available');
  }
}).catch((error) => {
  console.error('Error getting data:', error);
});
  },[])
  const orderNumber = localStorage.getItem("orderNumber")
return(<>
<NavBar />
<div className="px-[25px] mt-[20px] mb-[40px] flex justify-between flex-col">
    <div className="flex">
        <button
          type="button"
          onClick={() => {}}
          className="focus:outline-none ml-5 px-3 text-white bg-[#eb6700] hover:bg-[#eb6700] h-[34px]  justify-center items-center font-medium rounded-[12px] text-sm p-1  "
        >
          <IconButton
            width="15px"
            height="15px"
            backgroundPosition="-728px 725px"
          />
        </button>
        <h2 className=" text-2xl text-[#14171A] flex-1 text-[26px] text-center mr-[45px] font-bold leading-7 sm:truncate sm:text-3xl sm:tracking-tight">
        Order
        </h2>
      </div>
      <div className="flex flex-col gap-[32px] items-center justify-center mt-[150px]">
        <div className="flex flex-col gap-2 items-center justify-center">
            <p className="text-[#eb6700] text-center font-epilogue text-[23px] font-semibold leading-7">Order Confirmed !</p>
            <p className="text-[#14171A] text-center font-epilogue text-[23px] font-normal leading-7">Thankyou for Ordering from bistroboost</p>
        </div>
        <p className="text-[#667085] text-center font-epilogue text-[16px] font-normal leading-6">Your Order will be ready in {estimatedTime} min for pick up</p>
      </div>

      
      <div className=" flex flex-col items-center  m-5">
        <span className="text-[#667085] text-center font-epilogue text-[16px] font-normal leading-6">Order Number:</span>
        
         <div className="text-3xl max-w-2xl font-semibold p-5 border-4">{location.state.orderDetails || orderNumber}</div>
         </div>
      <div className="flex justify-center items-center mt-[150px]">
      <button onClick={() => {navigate("/")}} className="flex justify-center items-center rounded-full bg-[#eb6700] py-[15px] px-[40px] text-white text-center font-syne text-base font-medium leading-normal">
          Go To Home
        </button>
        </div>
</div>
</>)
}

export default Order;